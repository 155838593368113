<template>
  <div>
    <div class="top_select">
      <div>
        <el-input
          placeholder="请输入公司名称"
          v-model="listQuery.customerName"
          style="width: 200px; margin-right: 5px"
          size="small"
          @keyup.enter="getList"
        />
        <el-button type="primary" @click="getList()" size="small" icon="Search"
          >搜索</el-button
        >
      </div>
      <div>
        <!-- <el-button type="primary" size="small">导出</el-button> -->
      </div>
    </div>
    <el-table
      :data="termCustomerList"
      border
      @selection-change="handleSelectCustomer"
      style="width: 98%; margin: 0 auto; margin-top: 20px"
      height="680px"
      v-loading="listLoading"
    >
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column type="selection" width="55" align="center" fixed />
      <el-table-column
        type="index"
        label="序号"
        width="70"
        align="center"
        fixed
      />
      <el-table-column
        prop="customerName"
        label="公司名称"
        align="center"
        fixed
      />
      <el-table-column
        prop="linkman"
        label="联系人"
        width="150"
        align="center"
      />
      <el-table-column
        prop="linkmanTel"
        label="联系人电话"
        width="150"
        align="center"
      />
      <el-table-column
        prop="disCodes"
        label="业务负责人"
        width="150"
        align="center"
      />
      <el-table-column
        prop="terminationReason"
        label="解约原因"
        width="180"
        align="center"
        show-overflow-tooltip="false"
      />
      <el-table-column
        prop="terminationDate"
        label="解约时间"
        width="180"
        align="center"
      />
      <el-table-column label="操作" align="center" fixed="right" width="350">
        <template #default="scope">
          <el-button
            type="primary"
            size="small"
            @click="reCoop(scope, row)"
            plain
            icon="Refresh"
            >还原到合作客户</el-button
          >
          <el-button
            type="danger"
            size="small"
            @click="delDown(scope.row)"
            icon="Delete"
            plain
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="top_select">
      <div>
        <el-button
          type="primary"
          size="small"
          :disabled="this.sels.length === 0"
          @click="SaveAll()"
          icon="Refresh"
          >批量还原</el-button
        >
        <el-button
          type="danger"
          size="small"
          :disabled="this.sels.length === 0"
          @click="delAll()"
          icon="Delete"
          >批量删除</el-button
        >
      </div>
      <div class="pagination" style="margin-top: 0">
        <qzf-pagination
          v-show="total > 0"
          :total="total"
          v-model:page="listQuery.page"
          v-model:limit="listQuery.limit"
          @pagination="getList()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  getCustomerList,
  updateCustomer,
  delCustomer,
  saveStatus,
  batchDelCustomer,
} from "../../../api/crm";
export default {
  name: "termCustom",

  data() {
    return {
      total: "",
      sels: [],
      listQuery: {
        page: 1,
        limit: 20,
        customerType: "3",
        customerName: "",
      },
      termCustomerList: [],
      listLoading: false,
    };
  },

  mounted() {
    this.getList();
    this.init();
  },

  methods: {
    init() {
      this.$bus.off("termUpdate");
      this.$bus.on("termUpdate", (val) => {
        this.getList();
      });
    },
    getList() {
      this.listLoading = true;
      getCustomerList(this.listQuery).then((res) => {
        this.listLoading = false;
        if (res.data.msg == "success") {
          this.termCustomerList = res.data.data.list;
          this.total = res.data.data.total;
        }
      });
    },
    reCoop(e) {
      this.$confirm("确定还原到合作客户吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.termCustomerList[e.$index].customerType = "2";
          updateCustomer(this.termCustomerList[e.$index]).then((res) => {
            console.log(res);
            if (res.data.msg == "success") {
              this.$bus.emit("coopUpdate");
              this.$bus.emit("contractUpdate");
              this.getList();
              this.$qzfMessage("修改成功");
            }
          });
        })
        .catch(() => {});
    },
    //彻底删除
    delDown(row) {
      this.$confirm("确定删除客户吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delCustomer({ id: row.id }).then((res) => {
            if (res.data.msg == "success") {
              this.$qzfMessage("成功删除");
              this.getList();
            }
          });
        })
        .catch(() => {});
    },
    //批量删除或还原
    handleSelectCustomer(sels) {
      this.sels = sels;
    },
    delAll() {
      this.$confirm("确定删除客户吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let ids = [];
          this.sels.map((item) => {
            ids.push({
              id: item.id,
            });
          });
          batchDelCustomer(ids).then((res) => {
            if (res.data.msg == "success") {
              this.$qzfMessage("成功删除");
              this.getList();
            }
          });
        })
        .catch(() => {});
    },
    SaveAll() {
      this.$confirm("确定还原到合作客户吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = [];
          this.sels.map((item) => {
            params.push({
              id: item.id,
              customerType: "2",
            });
          });
          saveStatus(params).then((res) => {
            if (res.data.msg == "success") {
              this.$qzfMessage("修改成功");
              this.$bus.emit("coopUpdate");
              this.$bus.emit("contractUpdate");
              this.getList();
            }
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.top_select {
  width: 98%;
  display: flex;
  margin: 0 auto;
  margin-top: 20px;
  justify-content: space-between;
}
</style>
